import React, { useContext, useEffect } from 'react';

import { Button, Form } from 'semantic-ui-react';
import { withFormik, FormikProps } from 'formik';

import { LayoutContext } from 'src/components/layout/LayoutContext';
import * as Styles from 'src/styles/activation-codes.module.scss';
import { isEmpty } from 'lodash';
import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import { useReduxEffect } from '@pvolve/sdk/src/app/utils/useReduxEffect';
import { getErrorMessage } from 'src/utils/form-utils';
import { usePartner } from 'src/state/partner/hooks';
// import CheckoutAccountForm from 'src/components/checkout/CheckoutAccountForm';
// import * as FormStyles from 'src/styles/form-steps.module.scss';
// import { datadogRum } from '@datadog/browser-rum';

const {
    subscriptions: { setActivationCode },
} = Actions;

const connector = connect(
    (state: IState) => ({
        loading: Selectors.subscriptions.setActivationCode.loading(state),
    }),
    {
        submitActivationCode: (code: string) => setActivationCode.trigger({ code }),
    }
);

export enum Step {
    activate = 'activate',
    account = 'account/login',
}
// interface CheckoutStep {
//     name: Step;
//     complete: boolean;
// }

interface FormValues {
    activationCode: string;
}

interface ActivationFormProps extends ConnectedProps<typeof connector> {
    params: {
        slug?: string;
    };
}

const ActivationForm = ({
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldError,
    params,
}: ActivationFormProps & FormikProps<FormValues>) => {
    useReduxEffect(
        ({ payload }) => {
            if (payload?.message && payload?.response_code !== 0) {
                setFieldError('activationCode', payload?.message);
            }
        },
        [setActivationCode.success],
        []
    );
    const loading = useSelector(Selectors.subscriptions.setActivationCode.loading);

    const partnerSlug = params?.slug || '';
    const {
        partner: { logo, instructions },
    } = usePartner(partnerSlug.toLocaleLowerCase());
    // const [steps, setSteps] = useState([]);
    // const currentStepName = isEmpty(steps) ? '' : steps[currentStepIndex]['name'];
    const { layoutData, setLayoutData } = useContext(LayoutContext);

    useEffect(() => {
        if (logo && layoutData?.partnerLogo !== logo) {
            setLayoutData({
                layout: 'plain',
                headerBgColor: 'sea',
                partnerLogo: logo,
            });
        } else if (!layoutData?.layout) {
            setLayoutData({
                layout: 'plain',
                headerBgColor: 'black',
            });
        }
    }, [layoutData, logo, setLayoutData]);

    return (
        <div className={Styles.wrapper}>
            {/* <ol className={FormStyles.formSteps}>
                    <li> */}
            <Form onSubmit={handleSubmit} className={Styles.form}>
                {/* <h4>Enter Activation Code</h4> */}
                <p>{instructions || 'Please enter your activation code below.'}</p>
                <Form.Input
                    fluid
                    id="activationCode"
                    name="activationCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="activation code"
                    label={{
                        children: 'activation code',
                        htmlFor: 'activationcode',
                        className: values.activationCode ? '' : 'hidden',
                    }}
                    error={errors.activationCode && getErrorMessage(errors.activationCode)}
                    value={values.activationCode}
                />
                <div className={Styles.submitButton}>
                    <Button
                        type="submit"
                        fluid
                        disabled={
                            !values.activationCode || loading || !isEmpty(errors.activationCode)
                        }
                        loading={loading}
                    >
                        Redeem Code
                    </Button>
                </div>
            </Form>
            {/* </li>
                <CheckoutAccountForm active={true} complete={false} />
            </ol> */}
        </div>
    );
};

const EnhancedActivationForm = withFormik<ConnectedProps<typeof connector>, FormValues>({
    handleSubmit: ({ activationCode }, { props: { submitActivationCode } }) =>
        submitActivationCode(activationCode),
})(ActivationForm);

export default connector(EnhancedActivationForm);
